import Question from "../../models/Question.js";
import mwutils from "../../assets/utilities";

let empty = mwutils.getEmpty(Question);

export default {
	firestorePath: "questions",
	firestoreRefType: "collection",
	moduleName: "questions",
	statePropName: "data",
	namespaced: true,
	state: {
		icon: "",
		name: {
			single: "Question", 
			plural: "Questions"
		},
		path: "/questions"
	},
	sync: {
		where: [["status", "in", ["published"]]],
		defaultValues: { ...empty, status: "published" },
	},
	serverChange: {},

	getters: {
		standardDemographicsQuestions(){
			return [
				
				"m6LAVG74rlCf6MZ3XtPQ",
				"HdjvoKZVQQP2JMgZITSG",
				"5UdvXL1pe3EQV4TkMynX",
				"IQeF7y2sQep6qs9G6ilZ",
				"8xhYwJdvfpdcDwSeGEmL",
				"n1ZSSWDmfB1VdxBkvczW",
				"o9Fbv4IYX4BKWcpJ4cW2",
				"SaLkxIeKCX9Gh0SWKwec",
				"DZrg2FAMFxR3GifWA5ko",
				"CcgR7zLxbPVQbiUAen4T",
				"LUnsNuS25yEQiloiQ0Zf",
				"YY39TlaJrXldcG4LqAxz",
				"KEIkEVOaPxXwzDCdRro3",
				"A1HVgENJkoyNrWdlm2YP",
				"vD3u5d62GuWpupkr3v12",
				"xZqCVs6fZrxbHr97rxIf",
				"aZoTibDOBWBZggqdo12W",
				"bpi9VwEe5xI0asqkupjV",
				"IjSnXaFuG6duaAF2DkkW",
				"LjoarfyBOu9LedB3btmB",
				"zFQddQHifR3W9Q5yNO5O",
				"8knhe7PVnODuRx1V1MFP",

			];
		},
		libraryQuestions(state) {
			let values = Object.values(state.data);
			return values.filter((q) => q.in_library == true );
		},
		title(state) {
			return (id) => {
				let question = state.data[id];
				if (!question || !id) {
					return "";
				}
				if (question.type == "html") {
					return question.content;
				}
				return question.title;
			};
		},
		shortText(state) {
			return (id) => {
				let question = state.data[id];
				if (!question || !id) {
					return "";
				}
				
				return question.short_text || "";
			};
		},
		subquestions(state){
			return (id) => {
				let question = state.data[id];
				let subquestions = question.subquestions;
				if( !subquestions ){
					return [];
				}
				return Object.values(subquestions);
			}
		},
		isLabelled(state) {
			return (id) => {
				let question = state.data[id];
				if (!id || !question) {
					return null;
				}
				let questionType = question.type;
				let labelledTypes = ["checkboxes", "matrix"];
				return labelledTypes.includes(questionType);
			};
		},
		isHierarchical(state) {
			return (id) => {
				let question = state.data[id];
				if (!id || !question) {
					return null;
				}
				let questionType = question.type;
				let hierarchicalTypes = ["matrix"];
				return hierarchicalTypes.includes(questionType);
			};
		},
		isMultiple(state) {
			return (id) => {
				let question = state.data[id];
				if (!id || !question) {
					return null;
				}
				return question.multiple;
			};
		},
		labelledAnswer(state) {
			return (id, value) => {
				let question = state.data[id];
				let answer = question.answer_options[value];
				if (!answer) {
					return value;
				}
				return answer.text || value;
			};
		},
		textAnswer(_, getters) {
			return (id, value) => {
				if (getters.isLabelled(id)) {
					return getters.labelledAnswer(id, value);
				}
				return value;
			};
		},
		hierarchicalAnswer(state, getters) {
			return (id, value) => {
				let question = state.data[id];
				return Object.keys(value).map((q) => {
					let v = value[q];
					let subquestion = question.subquestions[q];
					let answer = "";
					if (subquestion) {
						answer = `${subquestion.text}: `;
					}
					answer += getters.textAnswer(id, v);
					answer += "\n";
					return answer;
				});
			};
		},
		parseValueToString(_, getters) {
			return (id, value) => {
				if (!value) {
					return "";
				}
				if (Array.isArray(value)) {
					// if (getters.isHierarchical(id)) {
					// 	return getters.hierarchicalAnswer(id, value);
					// } else {
					try {
						return value.map((v) => getters.textAnswer(id, v)).join(", ");
					} catch {
						return "";
					}
					// }
				} else {
					return getters.textAnswer(id, value);
				}
			};
		},
	},
	mutations: {},
	actions: {
		getLibraryQuestions({ dispatch }) {
			console.log("getting")
			dispatch("fetchAndAdd", {
				clauses: { where: [["in_library", "==", true]] },
			});
		},
	},
	model: {},
};
