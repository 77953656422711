<template>
	<v-app-bar app color="white" flat>
		<span style="font-weight-light">{{siteName}}</span>
		<v-spacer></v-spacer>
	</v-app-bar>
</template>

<style lang="less" scoped>
img {
	cursor: pointer;
}
</style>
<script>
export default {
	name: "TopNav",
	computed: {
		siteName(){
			return this.$store.state.site_title;
		}
	},
	methods: {
		goHome() {
			if (this.$route.path !== "/") {
				this.$router.push("/");
			}
		},
	},
};
</script>
