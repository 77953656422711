import utilities from "../../assets/utilities";

import mwutils from "../../assets/utilities";
import Appointment from "../../models/Appointment.js";

let empty = mwutils.getEmpty(Appointment);

export default {
	firestorePath: "appointments",
	firestoreRefType: "collection",
	moduleName: "appointments",
	statePropName: "data",
	namespaced: true,
	state: {},
	sync: {
		defaultValues: {
			...empty,
			status: "published",
		},
	},
	serverChange: {
		addedHook: function(updateStore, doc, _, { dispatch }) {
			dispatch("appointmentParticipants/fetchByKey", {
				value: doc.id,
				key: "appointment",
			});
			if (doc.video_room) {
				dispatch("videoRooms/fetchById", doc.video_room);
			}
			dispatch("projects/fetchById", doc.project);
			return updateStore(doc);
		},
	},
	mutations: {},
	getters: {
		activeAppointmentsForProject(state) {
			return (id) => {
				let appointments = Object.values(state.data).filter(
					(a) => a.project == id
				);
				return appointments.filter((a) => a.status == "published");
			};
		},
		dashboard(state, _, __, rootGetters) {
			return (trackedOnly) => {
				let appts = Object.values(state.data);
				appts = appts.filter((a) => a.status == "published");

				let now = new Date();
				now.setHours(0, 0, 0, 0);
				appts = appts.filter((a) => a.start && a.start.toDate() > now);

				if (trackedOnly) {
					let trackedProjects = rootGetters.auth.trackedProjects;
					appts = appts.filter((a) => trackedProjects.includes(a.project));
				}

				return utilities.sortByDate(appts, "start");
			};
		},
		checklist(state) {
			return (n) => {
				let startAfter = new Date();
				startAfter.setHours(0, 0, 0);

				let endBy = new Date();
				let day = endBy.getDate();
				endBy.setDate(day + (n - 1));
				endBy.setHours(11, 59, 59);

				let appts = Object.values(state.data)
					.filter((a) => a.start)
					.filter((a) => {
						return (
							a.start.toDate() > startAfter && a.start.toDate() < endBy
						);
					});

				return utilities.sortByDate(appts, "start", -1);
			};
		},
	},
	actions: {
		nextDays({ dispatch }, n) {
			let startAfter = new Date();
			startAfter.setHours(0, 0, 0);

			let endBy = new Date();
			let day = endBy.getDate();
			endBy.setDate(day + (n - 1));
			endBy.setHours(11, 59, 59);

			return dispatch("fetchAndAdd", {
				clauses: {
					where: [
						["start", ">", startAfter],
						["start", "<", endBy],
						["status", "==", "published"],
					],
					orderBy: "start",
					limit: 1000,
				},
			});
		},
		myNext10({ dispatch }) {
			let limit = 10;
			let startAfter = new Date();
			startAfter.setHours(0, 0, 0, 0);

			return dispatch("fetchAndAdd", {
				clauses: {
					where: [
						["start", ">", startAfter],
						["status", "==", "published"],
					],
					orderBy: "start",
					limit,
				},
			});
		},
		cancel({ dispatch }, id) {
			dispatch("patch", { id, status: "cancelled" });
		},
		fetchByProject({ dispatch }, id) {
			if (!id) {
				return;
			}
			dispatch("fetchAndAdd", {
				clauses: {
					limit: -1,
					where: [
						["project", "==", id],
						["status", "==", "published"],
					],
				},
			});
		},
		
	},
};
