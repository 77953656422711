export default {
	firestorePath: "projects",
	firestoreRefType: "collection",
	moduleName: "projects",
	statePropName: "data",
	namespaced: true,
	state: {
		icon: "mdi-archive-edit",
		name: {
			single: "Project",
			plural: "Projects",
		},
		path: "/projects",
	},
	sync: {
		where: [["status", "in", ["published"]]],
		defaultValues: {
			status: "published",
		},
	},
	serverChange: {
		addedHook: function(updateStore, doc, _, { dispatch }) {
			dispatch("users/fetchByIds", doc.clients, { root: true });
			return updateStore(doc);
		},
	},
	getters: {
		confirmationEmailTemplate(state){
			return (id) => {
				let project = state.data[id];
				return project ? project.confirmation_email : null
			}
		},
		name(state){
			return (id) => {
				let project = state.data[id];
				return project ? project.internal_name : ""
			}
		}
	},
	mutations: {},
	actions: {
		fetchCurrentProjects({ dispatch }) {
			return dispatch("openDBChannel", {
				clauses: {
					where: [["project_status", "==", "active"]],
				},
			});
		},
	},
};
