export default {
	firestorePath: "profileFields",
	firestoreRefType: "collection",
	moduleName: "profileFields",
	statePropName: "data",
	namespaced: true,
	state: {
		icon: "mdi-target",
		name: {
			single: "Profile Field", 
			plural: "Profile Fields"
		},
		path: "/profile-fields"
	},
	sync: {
	},
	getters: {
	},
	mutations: {},
	actions: {
    },
};
