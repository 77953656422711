import store from "../store";
import Vue from "vue";
export default {
    parseDateToMoment(date, tz ){
        if( date.isMoment ){
            return date;
        }
        date = date.toDate ? date.toDate() : date; 
        if( !tz ){
            tz = store.state.timezone;
        }
        return Vue.moment(date).tz(tz);
    },
    formatAsDateString( date, tz){
        let moment = this.parseDateToMoment(date, tz);
        return moment.format("DD/MM/YYYY");
    }, 
    formatAsUSDateString( date, tz){
        let moment = this.parseDateToMoment(date, tz);
        return moment.format("YYYY-MM-DD");
    }, 
    formatAsDateTimeString(date, tz){
        if( !date ){
            return "";
        }
        let moment = this.parseDateToMoment(date, tz);
        return moment.format("DD/MM/YYYY HH:mm" );
    },
    getHoursFromSlots(slots, tz ){
        let hours = []
        slots.forEach((slot) => {
            // get the earliest and latest hour marker
            let start = this.parseDateToMoment(slot.start, tz );
            let end = this.parseDateToMoment(slot.end, tz );
            let earliest = start.startOf("hour");
            let latest = end.endOf("hour");
            while (earliest < latest) {
                // deduplicating -> as we are rounding the slots up and down to get full hour markers, it's possible to get duplicates
                if (hours.length == 0 || hours[hours.length - 1] < earliest) {
                    hours.push(earliest.clone());
                }

                earliest.add(1, "hour");
            }
        });
        return hours;
    },
    getQuartersFromSlots(slots, tz ){
        let hours = []
        slots.forEach((slot) => {
            // get the earliest and latest hour marker
            let start = this.parseDateToMoment(slot.start, tz );
            let end = this.parseDateToMoment(slot.end, tz );
            let earliest = start.startOf("hour");
            let latest = end.endOf("hour");
            while (earliest < latest) {
                // deduplicating -> as we are rounding the slots up and down to get full hour markers, it's possible to get duplicates
                if (hours.length == 0 || hours[hours.length - 1] < earliest) {
                    hours.push(earliest.clone());
                }

                earliest.add(15, "minutes");
            }
        });
        return hours;
    }, 
    length({start, end}){
        var duration = Vue.moment.duration(end.diff(start));
        var minutes = duration.asMinutes();
        return minutes;
    },
    durationAsString(minutes){
        if( minutes < 61 ) {
            return `${minutes} minutes`
        }
        else {
            let hours = Math.floor( minutes / 60 );
            let additionalMinutes = minutes % 60;
            return `${hours} hour${hours > 1 ? 's' : ''} ${additionalMinutes} minutes` 
        }
    }
}