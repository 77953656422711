export default {
	getByKey(state) {
		return (storeModule, key, value, returnObject) => {
			let items = state[storeModule].data;
			items = Object.values(items);
			items = items.filter((i) => i[key] == value);
			if (returnObject) {
				items = Object.fromEntries(items.map((v) => [v.id, v]));
			}
			return items;
		};
	},
	getByKeyDoesNotContain(state) {
		return (storeModule, key, value, returnObject) => {
			let items = state[storeModule].data;
			items = Object.values(items);
			items = items.filter((i) => !i[key] || !i[key].includes(value));
			if (returnObject) {
				items = Object.fromEntries(items.map((v) => [v.id, v]));
			}
			return items;
		};
	},
	getByKeyContains(state) {
		return (storeModule, key, value, returnObject) => {
			let items = state[storeModule].data;
			items = Object.values(items);
			items = items.filter((i) => i[key] && i[key].includes(value));
			if (returnObject) {
				items = Object.fromEntries(items.map((v) => [v.id, v]));
			}
			return items;
		};
	},
	getByKeyIn(state) {
		return (storeModule, key, value, returnObject) => {
			let items = state[storeModule].data;
			items = Object.values(items);
			items = items.filter((i) => value.includes(i[key]));
			if (returnObject) {
				items = Object.fromEntries(items.map((v) => [v.id, v]));
			}
			return items;
		};
	},
	getParticipantContacts(state) {
		return (id) => {
			let appointmentParticipant = state.appointmentParticipants.data[id];
			if (!appointmentParticipant) {
				return null;
			}
			let user = state.users.data[appointmentParticipant.user];
			if (!user) {
				return null;
			}
			let name = user.name;
			if (!name) {
				name = `${user.first_name} ${user.last_name}`;
			}
			let contacts = {
				email: user.email,
				phone: user.phone,
				name,
			};

			return contacts;
		};
	},
	getAppointmentFieldsForTemplates(state) {
		return (key, values) => {
			let appointment_participant, appointment, project, user;
			if (values.appointment_participant) {
				appointment_participant =
					state.appointmentParticipants.data[
						values.appointment_participant
					];
			}
			if (values.appointment) {
				appointment = state.appointments.data[values.appointment];
			} else if (appointment_participant) {
				appointment =
					state.appointments.data[appointment_participant.appointment];
			}
			if (
				[
					"incentive",
					"topic",
					"project",
					"other_info",
					"confirmation_email",
					"reminder_email",
				].includes(key)
			) {
				if (values.project) {
					project = state.projects.data[values.project];
				} else if (appointment) {
					project = state.projects.data[appointment.project];
				}
			}
			if (values.user) {
				user = state.users.data[values.user];
			} else if (appointment_participant) {
				user = state.users.data[appointment_participant.user];
			}

			let value;

			switch (key) {
				case "site_url":
					value = values.site_url;
					break;
				case "admin_name":
					value = state.auth.data.first_name;
					break;
				case "user":
					value = user.id;
					break;
				case "name":
					value = user.first_name ? user.first_name : user.name;
					break;
				case "phone":
					value = user.phone;
					break;
				case "incentive":
					value = project.incentive;
					break;
				case "topic":
					value = project.topic;
					break;
				case "confirmation_email":
					value = project.confirmation_email;
					break;
				case "reminder_email":
					value = project.reminder_email;
					break;
				case "other_info":
					value = project.other_info;
					break;
				case "project":
					value = project.public_name ? project.public_name : "";
					break;
				case "time":
					value = appointment.start
						? appointment.start
								.toDate()
								.toLocaleTimeString(
									{},
									{ minute: "2-digit", hour: "2-digit" }
								)
						: "";
					break;
				case "date":
					value = appointment.start
						? appointment.start.toDate().toLocaleDateString(
								{},
								{
									weekday: "long",
									year: "numeric",
									month: "long",
									day: "numeric",
								}
							)
						: "";
					break;
				case "link":
					value = appointment.participation_link;
					break;
				case "join_id":
					value = appointment_participant.id;
					break;
				default:
					value = "";
			}
			if (!value) {
				value = "";
			}

			return value;
		};
	},
};
