export default {
	firestorePath: "messages",
	firestoreRefType: "collection",
	moduleName: "messages",
	statePropName: "data",
	namespaced: true,
	state: {
		messages: {
			chase_participant: {
				template: "chase_participant_sms",
				fields: [
					"name",
					"incentive",
					"admin_name",
					"date",
					"time",
					"topic",
				],
			},
			chase_confirmation: {
				template: "chase_appointment_confirmation",
				fields: [
					"name",
					"incentive",
					"admin_name",
					"date",
					"time",
					"topic",
				],
			},
			check_availability: {
				template: "check_availability_sms",
				fields: [
					"name",
					"incentive",
					"admin_name",
					"date",
					"time",
					"topic",
				],
			},
			incentive_reminder: {
				template: "incentive_reminder_sms",
				fields: [
					"name",
					"incentive",
					"admin_name",
					"date",
					"time",
					"topic",
				],
			},
			appointment_reminder: {
				template: "appointment_reminder_sms",
				fields: [
					"name",
					"incentive",
					"admin_name",
					"date",
					"time",
					"topic",
				],
			},
			reschedule: {
				template: "reschedule_sms",
				fields: [
					"name",
					"incentive",
					"admin_name",
					"date",
					"time",
					"topic",
				],
			},
		}
	},
	mutations: {},
	actions: {
		fetchLastForUser({rootState, dispatch }, id ){
			let user = rootState.users.data[id];
			if( !user || !user.phone ){
				return;
			}

			return dispatch("fetchAndAdd", {
				clauses: {
					where: [["to", "==", user.phone]],
					orderBy: ["created_at", "desc"],
					limit: 1,
				},
			}).then( res => {
                if( !res.empty ){
                    return {id: res.docs[0].id, ...res.docs[0].data()}
                }
            })

		},
		getBody({dispatch}, {body, template, data}){
			if( body ){
				return body;
			}
			else{
				return dispatch(
					"templates/fill",
					{ id: template, data },
					{ root: true }
				)
			}
		},
		sendOut({ dispatch, rootGetters, state }, { sms, values, body, to }) {
			return new Promise((resolve, reject) => {
				let message = state.messages[sms];
				if( !message ){
					reject(new Error("No template found"))
				}
				let fields = {};
				message.fields.forEach((field) => {
					fields[field] = rootGetters.getAppointmentFieldsForTemplates(field, values);
				});
				dispatch( "getBody", {body, template: message.template, data: fields } ).then( body => {
					to = to || rootGetters.getAppointmentFieldsForTemplates("phone", values );
					if( !to ){
						reject(new Error("No phone number"));
					}
					body = body.replace(/(<([^>]+)>)/gi, "");
					var doc = {
						to,
						user: rootGetters.getAppointmentFieldsForTemplates("user", values ),
						body,
						template: message.template,
						status: "published",
					};
	
					for( var key in values ){
						doc[key] = values[key];
					}
					return dispatch("insert", doc).then( () => {
						resolve();
					}).catch( () =>{
						reject(new Error("Sending Error"))
					})
				})
				
			})

		},
	},
};
