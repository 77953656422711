export default {
	firestorePath: "consents",
	firestoreRefType: "collection",
	moduleName: "consents",
	statePropName: "data",
	namespaced: true,
	state: {},
	serverChange: {},
	getters: {},
	mutations: {},
	actions: {
		fetchByAppointmentParticipant({ dispatch }, id) {
			dispatch("fetchAndAdd", {
				clauses: {
					where: [["appointment_participant", "==", id]],
				},
			})
		},
	},
	model: {},
};
