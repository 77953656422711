import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import firebase from "firebase";
import VueI18n from "vue-i18n";
import english from "@/assets/translations/english.js";
import "./plugins/vuetify-mask.js";
import VueGtag from "vue-gtag";

import mwutils from "@/assets/utilities.js";
import mwsurveyutils from "@/assets/surveyUtilities.js";
import mwtimeutils from "@/assets/timeUtilities.js";

// import * as Sentry from "@sentry/vue";
// import { BrowserTracing } from "@sentry/tracing";
// Sentry.init({
// 	Vue,
// 	logErrors: true,
// 	dsn:
// 		"https://09b67f5f9b55496a818df624a74b1cca@o397452.ingest.sentry.io/6702588",
// 	integrations: [
// 		new BrowserTracing({
// 			routingInstrumentation: Sentry.vueRouterInstrumentation(router),
// 			tracingOrigins: ["localhost", "xadmin.m.works", /^\//],
// 		}),
// 	],
// 	tracesSampleRate: 1.0,
// });

Vue.config.productionTip = false;

Vue.prototype.mwsurveyutils = mwsurveyutils;
Vue.prototype.mwutils = mwutils;
Vue.prototype.mwtimeutils = mwtimeutils;

Vue.use(VueMoment, {
	moment,
});

Vue.use(VueI18n);

const i18n = new VueI18n({
	locale: "en",
	fallbackLocale: "en",
	messages: {
		en: english,
	},
});

var firebaseConfig = {
	apiKey: process.env.VUE_APP_FIREBASE_CONFIG_APIKEY,
	authDomain: process.env.VUE_APP_FIREBASE_CONFIG_AUTHDOMAIN,
	projectId: process.env.VUE_APP_FIREBASE_CONFIG_PROJECTID,
	storageBucket: process.env.VUE_APP_FIREBASE_CONFIG_STORAGEBUCKET,
	messagingSenderId: process.env.VUE_APP_FIREBASE_CONFIG_MESSAGINGSENDERID,
	appId: process.env.VUE_APP_FIREBASE_CONFIG_APPID,
	measurementId: process.env.VUE_APP_FIREBASE_CONFIG_MEASUREMENTID,
};

firebase.initializeApp(firebaseConfig);

const appCheck = firebase.appCheck();
appCheck.activate("6LdYevwgAAAAAB2mvuXPzfLprRzJTrsqveypCImf", true);

Vue.use(
	VueGtag,
	{
		config: { id: firebaseConfig.measurementId },
	},
	router
);

firebase.getCurrentUser = () => {
	return new Promise((resolve, reject) => {
		const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
			unsubscribe();
			resolve(user);
		}, reject);
	});
};

firebase.auth().onAuthStateChanged((user) => {
	if (user) {
		store.dispatch("auth/start").catch(() => {
			router.replace("/error");
		});
	}
});

new Vue({
	i18n,
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount("#app");
