
export default {
	firestorePath: "videoRooms",
	firestoreRefType: "collection",
	moduleName: "videoRooms",
	statePropName: "data",
	namespaced: true,
	state: {},
	sync: {
		where: [["status", "in", ["published"]]],
		defaultValues: {
			status: "published",
			clients: [],
			identities: {},
			members: [],
			moderator: {},
			participants: {}, 
			start: null, 
			waiting_room: []
		},
	},
	serverChange: {
	},
	getters: {
	},
	mutations: {},
	actions: {
		createRoom({rootState, rootGetters, dispatch}, appointment ){
			let participants = rootGetters.getByKey("appointmentParticipants", "appointment", appointment.id );
			participants = participants.map( p => p.user );
			let project = rootState.projects.data[appointment.project];
			if( !project ){
				return;
			}			
			let room = {
				project: appointment.project,
				start: appointment.start,
				participants: participants, 
				clients: project.clients,
			}
			return dispatch("insert", room ).then( id => {
				dispatch("appointments/patch", {id: appointment.id, video_room: id }, {root: true} );
				dispatch("videoRoom/fetchRoom", id, {root: true });
			})
		},
	},
	model: {},
};
