import mwutils from "../../assets/utilities";
import Handlebars from "handlebars";
export default {
	firestorePath: "templates",
	firestoreRefType: "collection",
	moduleName: "templates",
	statePropName: "data",
	namespaced: true,
	state: {
		icon: "mdi-email-edit",
		name: {
			single: "Template",
			plural: "Templates",
		},
		path: "/templates",
	},
	sync: {
		guard: ["name"],
		where: [["status", "in", ["published"]]],
		defaultValues: {
			status: "published",
		},

		insertHookBeforeSync: (updateFirestore, doc) => {
			doc.id = mwutils
				.strToKey(doc.name)
				.split("-")
				.join("");
			delete doc.name;
			return updateFirestore(doc);
		},
		patchHookBeforeSync: (updateFirestore, doc) => {
			doc.html = doc.html.replace("&gt;", ">")
			return updateFirestore(doc);
		},
	},
	getters: {
		
	},
	mutations: {},
	actions: {
		fill({ dispatch }, { id, data }) {
			if( !id ){
				return data.body || "";
			}
			return dispatch("fetchById", id).then((res) => {
				let t = res.html;
				const template = Handlebars.compile(t);
				return template(data);
			});
		},
	},
};
