export default {
	firestorePath: "organisations",
	firestoreRefType: "collection",
	moduleName: "organisations",
	statePropName: "data",
	namespaced: true,
	state: {
		icon: "mdi-office-building",
		name: {
			single: "Organisation",
			plural: "Organisations",
		},
		path: "/organisations",
	},
	sync: {
		defaultValues: {
			status: "published",
		},
		where: [["status", "in", ["published"]]],
	},
	serverChange: {},
	getters: {},
	mutations: {},
	actions: {},
	model: {},
};
