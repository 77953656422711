export default {
    methodology: {
        value: "methodology",
        display: true,
        items: ["video", "f2f", "phone", "unattended"],
        try_items: "project.methodologies"
    }, 
    start: {
        value: "start", 
        display: true,
    },
    duration: {
        value: "duration", 
    },
    video_medium: {
        value: "video_medium", 
        display: false
    }, 
    participation_link: {
        value: "participation_link", 
        display: false
    },
    quota: {
        value: "quota", 
        display: true,
        items: [],
        try_items: "project.quotas",
        default: ""
    }, 
}