import timezones from "./timezones.js"
export default {
    site_title: "MW Admin Area", 
    page_title: "MW Admin Area", 
    timezone: "Europe/London",    
    colors: ["primary", "accent", "orange",  "blue", "pink", "purple", "green", "yellow" ],
    participant_colors: ["#85bd5f", "secondary", "darkblue",  "lightblue", "pink", "yellow", "green" ],
    status_colors: {
        confirmed: "primary", 
        cancelled: "error", 
        complete: "success",
        preferred: "primary",
        available: "grey",
        unavailable: "grey",
        booked: "green",
    },
    timezones: timezones, 
    video_mediums: [
        'internal',
        'skype',
        'ms teams',
        'google meet',
        'zoom',
        'other',
    ],

}