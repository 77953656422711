export default {
	state: {
		contact_detail_headers: [
			{
				value: "phone",
				text: "Phone",
				source: "appointment_participant",
				display: "default",
				filterable: true,
				col_type: "appointments",
			},
			{
				value: "email",
				text: "Email",
				source: "appointment_participant",
				display: "default",
				col_type: "appointments",
				filterable: true,
			},
		],
		scheduling_table_headers: [
			{
				value: "start",
				text: "Start",
				disabled: true,
				source: "appointment",
				display: "date",
				col_type: "appointments",
				filterable: false,
			},
		],
		appointment_table_headers: [
			{
				value: "methodology",
				text: "Methodology",
				expanded: false,
				source: "appointment",
				display: "prettyPrint",
				filterable: true
			},
			{
				value: "name",
				text: "Name",
				expanded: true,
				source: "participant",
				display: "default",
				filterable: true
			},
			{
				value: "phone",
				text: "Phone",
				expanded: true,
				source: "participant",
				display: "default",
				filterable: true
			},
			{
				value: "email",
				text: "Email",
				expanded: true,
				source: "participant",
				display: "default",
				filterable: true
			},
			{
				value: "appointment_status",
				text: "Status",
				expanded: true,
				source: "participant",
				display: "chip",
				filterable: true
			},
			{
				value: "participant_reports_complete",
				text: "Participant Says Complete",
				expanded: true,
				source: "participant",
				display: "checkbox",
				disabled: true,
				filterable: true
			},
			{
				value: "quota",
				text: "Quota",
				expanded: true,
				source: "participant",
				display: "prettyPrint",
				filterable: true
			},
			{
				value: "consented",
				text: "Consented",
				expanded: true,
				disabled: false,
				source: "participant",
				display: "consent",
				filterable: true
			},
			{
				value: "participation_link",
				text: "Link",
				expanded: false,
				source: "appointment",
				display: "click_link",
				filterable: true
			},
			{
				value: "video_medium",
				text: "Medium",
				expanded: false,
				source: "appointment",
				display: "prettyPrint",
				filterable: true
			},
			{
				value: "show_to_client",
				text: "Show To Client",
				expanded: true,
				source: "participant",
				display: "checkbox",
				filterable: true
			},
		]
	},
	getters: {
		projectCustomColumns(_, __, rootState) {
			return (id) => {
				let project = rootState.projects.data[id];
				return project.custom_fields.map((f) => ({
					text: f,
					value: f,
					source: "participant",
					display: "default",
					filterable: true,
				}));
			};
		},
		projectColumns(state, getters, rootState) {
			return (id) => {
				let project = rootState.projects.data[id];
				let cols = [];
				if( !project ){
					return cols;
				}
				if (project.include_scheduling) {
					cols = cols.concat(state.scheduling_table_headers);
				}
				cols = cols.concat(state.appointment_table_headers);
				if (!project.hide_contact_details) {
					cols = cols.concat(state.contact_detail_headers);
				}
				let custom_columns = getters.projectCustomColumns(id);
				cols = cols.concat(custom_columns);
				return cols;
			};
		},
	},
	namespaced: true,
};
