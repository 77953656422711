import mwutils from "../../assets/utilities";

export default {
	firestorePath: "categories",
	firestoreRefType: "collection",
	moduleName: "categories",
	statePropName: "data",
	namespaced: true,state: {
		icon: "",
		name: {
			single: "Category", 
			plural: "Categories"
		},
		path: "/categoryOptions"
	},
	sync: {
		where: [["status", "in", ["published"]]],
		defaultValues: {
			status: "published",
		},
		insertHookBeforeSync: (updateFirestore, doc) => {
			let id = doc.name.split(" ").join("");
			id = mwutils.lowerCaseFirst(id);
			doc.id = id;
			return updateFirestore(doc);
		},
	},
	serverChange: {
		addedHook: function(updateStore, doc, _, { dispatch }) {
			var options = doc.options;
			dispatch("categoryOptions/fetchByIds", options);
			return updateStore(doc);
		},
	},
	getters: {
		getOptions(state) {
			return (category) => {
				return state.data[category] ? state.data[category].options : [];
			};
		},
	},
	mutations: {},
	actions: {},
	model: {},
};
