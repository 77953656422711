export default {
	firestorePath: "emails",
	firestoreRefType: "collection",
	moduleName: "emails",
	statePropName: "data",
	namespaced: true,
	state: {
		messages: {
			general_email_to_user: {
				fields: ["name"],
			},
			incentive_reminder: {
				template: "incentive_reminder",
				fields: ["name", "client"],
			},
			incentive_request: {
				template: "incentive_request",
				fields: ["name", "client"],
			},
			video_reminder: {
				template: "video_appointment_reminder_email",
				fields: ["name", "join_id"],
			},
			registration_reminder: {
				template: "registration_reminder_email",
				fields: ["site_url", "name"],
			},
			consent_reminder: {
				template: "consent_email",
				fields: ["name", "project", "incentive", "admin_name"],
			},
			chase_participant: {
				template: "chase_email",
				fields: [
					"name",
					"project",
					"incentive",
					"admin_name",
					"date",
					"time",
					"participation_link",
					"other_info",
					"topic",
				],
			},
			appointment_reminder: {
				custom_template: "reminder_email",
				template: "appointment_reminder_email",
				fields: [
					"name",
					"project",
					"incentive",
					"admin_name",
					"date",
					"time",
					"participation_link",
					"other_info",
					"topic",
				],
			},
			appointment_confirmation: {
				custom_template: "confirmation_email",
				template: "appointment_confirmation_email",
				fields: [
					"name",
					"project",
					"incentive",
					"admin_name",
					"date",
					"time",
					"participation_link",
					"other_info",
					"topic",
				],
			},
			chase_confirmation: {
				template: "chase_confirmation_email",
				fields: [
					"name",
					"project",
					"incentive",
					"admin_name",
					"date",
					"time",
					"topic",
				],
			},
		},
	},
	mutations: {},
	getters: {
		getTemplate(state, _, __, rootGetters) {
			return (email, values) => {
				let template = "";
				let message = state.messages[email];
				if (message.custom_template) {
					template = rootGetters.getAppointmentFieldsForTemplates(
						message.custom_template,
						values
					);
				} else {
					template = message.template;
				}
				return template;
			};
		},
	},
	actions: {
		sendOut(
			{ dispatch, getters, rootGetters, state },
			{ email, values, template, to }
		) {
			return new Promise((resolve, reject) => {
				if (!state.messages[email]) {
					reject(new Error("No template found"));
				}
				let data = {};
				state.messages[email].fields.forEach((field) => {
					data[field] = rootGetters.getAppointmentFieldsForTemplates(
						field,
						values
					);
				});
				template = template || getters.getTemplate(email, values);
				if (!to) {
					to = rootGetters.getAppointmentFieldsForTemplates(
						"user",
						values
					);
				}

				let doc = {
					toUids: [to],
					template: {
						name: template,
						data,
					},
				};

				for (var key in values) {
					doc[key] = values[key];
				}

				return dispatch("insert", doc)
					.then(() => {
						resolve();
					})
					.catch(() => {
						reject(new Error("Sending Error"));
					});
			});
		},
		fetchByUser({ dispatch }, id) {
			if (!id) {
				return;
			}
			dispatch("fetchAndAdd", {
				clauses: { where: [["toUids", "array-contains", id]] },
			});
		},
		fetchLastForUser({ dispatch }, id) {
			if (!id) {
				return;
			}
			return dispatch("fetchAndAdd", {
				clauses: {
					where: [["toUids", "array-contains", id]],
					orderBy: ["created_at", "desc"],
					limit: 1,
				},
			}).then((res) => {
				if (res.docs) {
					return { id: res.docs[0].id, ...res.docs[0].data() };
				}
			});
		},
	},
};
