export default {
	firestorePath: "participantAvailability",
	firestoreRefType: "collection",
	moduleName: "participantAvailability",
	statePropName: "data",
	namespaced: true,
	state: {},
	sync: {
		where: [["status", "in", ["published"]]],
		defaultValues: {
			participant: null, // the participant id
			project: null, // the project id
		},
	},
	serverChange: {
		addedHook: function(updateStore, doc, _, { dispatch }) {
			var participant = doc.participant;
			if( participant && typeof participant == "string" ){
				dispatch("users/fetchById", participant);
				return updateStore(doc);
			}
		},
	},
	getters: {
		userForProject(state){
			return (participant, project ) => {
				let results = Object.values(state.data);
				return results.filter( res => {
					return res.participant == participant && res.project == project;
				})
			}
		}
	},
	mutations: {},
	actions: {
		fetchByProject({ dispatch }, id) {
			return dispatch("fetchAndAdd", {
				clauses: { where: [["project", "==", id]] },
			});
		},
		fetchByUserForProject({ dispatch }, { participant, project }) {
			return dispatch("fetchAndAdd", {
				clauses: {
					where: [
						["project", "==", project],
						["participant", "==", participant],
					],
				},
			});
		},
	},
	model: {},
};
