import Handlebars from "handlebars";
import store from "../store";
import utilities from "./utilities";
export default {
	renderQuestionContent(id) {
		let question = store.state.questions.data[id];
		if (!question) {
			return "";
		}
		if (question.type == "html") {
			return question.content;
		}
		return question.title;
	},
	renderAnswerOptionContent(id) {
		let question = store.state.questions.data[id];
		let options = question.answer_options;
		let answers = Object.values(options)
		answers = utilities.sortByKey(
			answers,
			"position"
		);
		answers = answers.map((o) => o.text);
		return answers.join(" | ");
	},
	renderSubquestionContent(id) {
		let question = store.state.questions.data[id];
		let s = Object.values(question.subquestions)		
		s = utilities.sortByKey(
			s,
			"position"
		);
		s = s.map((o) => o.text);
		return s.join(" | ");
	},
	isVisible( obj, data){
		let ruleString =  obj.visible_if;
		if( !ruleString ){
			return true;
		}
		
		let test = Handlebars.compile(ruleString);
		let result = test(data);
		try {
			return eval(result)
		}
		catch {
			return false;
		}
	}, 
};
