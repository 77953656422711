export default {
    appointment_status: {
        value: "appointment_status", 
        display: true,
        items: ["provisional", "confirmed", "complete", "cancelled", "cancelled-client"],
        try_items: "project.appointment_statuses",
        default: "provisional"
    }, 
    payment_status: {
        value: "payment_status", 
        display: true,
        items: ["unpaid", "paid", "contacted", "problem", "ready"],
        default: "unpaid"
    }, 
    quota: {
        value: "quota", 
        display: true,
        items: [],
        try_items: "project.quotas",
        default: ""
    }, 
    appointment: {
        value: "appointment", 
        display: true,
    },
    consented: {
        value: "consented", 
        display: true,
        default: false, 
        component: "v-checkbox"
    },
    user: {
        value: "user", 
        display: true,
    }, 
    show_to_client: {
        value: "show_to_client", 
        display: true,
        default: true,
        component: "v-checkbox"
    }, 
    participant_reports_complete: {
        value: "participant_reports_complete", 
        display: true, 
        default: false,
        component: "v-checkbox"
    },
    participant_set_status_at: {
        value: "participant_set_status_at", 
        display: false,
        default: null
    }, 
    participant_comments: {
        value: "participant_comments", 
        display: false,
        default: null
    }, 
    client_comments: {
        value: "client_comments", 
        display: false,
        default: null
    }, 
}