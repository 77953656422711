export default {
	firestorePath: "incentiveSubmissions",
	firestoreRefType: "collection",
	moduleName: "incentiveSubmissions",
	statePropName: "data",
	namespaced: true,
	state: {},
	serverChange: {
		addedHook: function(updateStore, doc, _, { dispatch }) {
			if (doc.user) {
				dispatch("users/fetchById", doc.user);
			}
			if (doc.created_by) {
				dispatch("users/fetchById", doc.created_by);
			}
			if (doc.project) {
				dispatch("projects/fetchById", doc.project);
			}
			return updateStore(doc);
		},
	},
	getters: {
		getByFilters(state) {
			return ({
				projects = [],
				user = null,
				date_start,
				date_end,
				created_by = [],
				statuses = [],
			}) => {
				let records = Object.values(state.data);
				if (projects.length) {
					records = records.filter((f) => projects.includes(f.project));
				}
				if (user ) {
					records = records.filter((f) => user == f.user);
				}
				if (created_by.length) {
					records = records.filter((f) => created_by.includes(f.created_by));
				}
				if (statuses.length) {
					records = records.filter((f) => statuses.includes(f.status));
				}
				if (date_start) {
					records = records.filter((f) => { 
						return f.created_at >  date_start;
					} );
				}
				if (date_end) {
					records = records.filter((f) => { 
						return f.created_at < date_end;
					} );
				}
				return records;
			};
		},
	},
	mutations: {},
	actions: {
		createUser({ state, dispatch }, id) {
			let record = state.data[id];
			let doc = {
				first_name: record.first_name,
				last_name: record.last_name,
			};
			dispatch("users/new", doc, { root: true }).then((user) => {
				dispatch("patch", { id, user });
			});
		},
		fetchByFilters(
			{ dispatch },
			{
				projects = [],
				users = [],
				created_by = [],
				date_start,
				date_end,
				statuses = [],
			}
		) {
			let where = [];
			if (projects.length) {
				where.push(["project", "in", projects]);
			}
			if (users.length) {
				where.push(["user", "in", users]);
			}
			if (created_by.length) {
				where.push(["created_by", "in", created_by]);
			}
			if (statuses.length) {
				where.push(["status", "in", statuses]);
			}
			if (date_start) {
				where.push(["created_at", ">", date_start]);
			}
			if (date_end) {
				where.push(["created_at", "<", date_end]);
			}
			return dispatch("fetchAndAdd", { clauses: { where, limit: -1 } } )
		},
	},
};
