export default {
	getFields(model) {
		return Object.values(model).filter((field) => field.display);
	},
	getEmpty(model) {
		var obj = {};
		Object.values(model).forEach(
			(field) => (obj[field.value] = field.default || null)
		);
		return obj;
	},
	uniqueId() {
		return Math.random()
			.toString(16)
			.slice(2);
	},
	prettyPrint: function(str, strCase) {
		if (!str) {
			return "";
		}
		str = str.split("_").join(" ");
		str = str.split("-").join(" ");
		switch (strCase) {
			case "TITLE":
				var sentence = str.toLowerCase().split(" ");
				for (var i = 0; i < sentence.length; i++) {
					sentence[i] =
						sentence[i][0].toUpperCase() + sentence[i].slice(1);
				}
				return sentence.join(" ");
			case "SENTENCE":
				str = str.toLowerCase();
				str = str[0].toUpperCase() + str.slice(1);
				return str;
			case "LOWER":
				return str.toLowerCase();
			case "UPPER":
				return str.toUpperCase();
			default:
				return str;
		}
	},
	isId(string) {
		let f = string.search(/[!@#$%^&*()_+\-=[\]{};':"\\\s]/);
		return f == -1;
	},
	validateEmail(str) {
		if (
			/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
				str
			)
		) {
			return true;
		}
		return false;
	},
	nameToId(doc) {
		doc.id = this.strToKey(doc.name)
			.split("-")
			.join("");
		doc.id = this.lowerCaseFirst(doc.id);
		delete doc.name;
		return doc;
	},
	byString(o, s) {
		s = s.replace(/\[(\w+)\]/g, ".$1");
		s = s.replace(/^\./, "");
		var a = s.split(".");
		for (var i = 0, n = a.length; i < n; ++i) {
			var k = a[i];
			if (k in o) {
				o = o[k] || false;
			} else {
				return;
			}
		}
		return o;
	},
	strToKey: function(str) {
		if (!str) {
			return "";
		}
		str = str.split(" ").join("-");
		str = str.replace(/[^\w\s]/gi, "-");
		return str.toLowerCase();
	},
	sortByKey: function(array, key) {
		return array.sort(function(a, b) {
			var x = a[key];
			var y = b[key];
			return x < y ? -1 : x > y ? 1 : 0;
		});
	},
	sortByDate: function(array, key = "created_at", direction = 1) {
		return array.sort(function(a, b) {
			if (!a[key] || !b[key] || !a[key].toDate || !b[key].toDate) {
				return 0;
			}
			var x = a[key].toDate();
			var y = b[key].toDate();
			return x < y ? direction : x > y ? -direction : 0;
		});
	},
	moveItemInArray(id, array, direction) {
		let delta = direction == "up" ? -1 : 1;
		var index = array.indexOf(id);
		var newIndex = index + delta;
		if (newIndex < 0 || newIndex == array.length) return;
		var indexes = [index, newIndex].sort((a, b) => a - b);
		array.splice(indexes[0], 2, array[indexes[1]], array[indexes[0]]);
	},
	getPositions(array, property = "position") {
		var keys = Object.keys(array);
		keys = keys.sort((a, b) => {
			var x = array[a][property];
			var y = array[b][property];
			return x < y ? -1 : x > y ? 1 : 0;
		});
		return keys;
	},
	itemsArray(obj, text = "name") {
		return Object.keys(obj).map((id) => {
			return {
				value: id,
				text: obj[id][text],
			};
		});
	},
	upperCaseFirst(str) {
		return str.charAt(0).toUpperCase() + str.substr(1);
	},
	lowerCaseFirst(str) {
		return str.charAt(0).toLowerCase() + str.substr(1);
	},
	uniqueArray(arr) {
		return arr.filter((a, i) => arr.indexOf(a) == i);
	},
	resizableGrid(table) {
		var row = table.getElementsByTagName("tr")[0],
			cols = row ? row.children : undefined;
		if (!cols) return;

		table.style.overflow = "hidden";

		var tableHeight = table.offsetHeight;

		for (var i = 0; i < cols.length; i++) {
			var div = createDiv(tableHeight);
			cols[i].appendChild(div);
			cols[i].style.position = "relative";
			setListeners(div);
		}

		function setListeners(div) {
			var pageX, curCol, nxtCol, curColWidth, nxtColWidth;

			div.addEventListener("mousedown", function(e) {
				curCol = e.target.parentElement;
				nxtCol = curCol.nextElementSibling;
				pageX = e.pageX;

				var padding = paddingDiff(curCol);

				curColWidth = curCol.offsetWidth - padding;
				if (nxtCol) nxtColWidth = nxtCol.offsetWidth - padding;
			});

			div.addEventListener("mouseover", function(e) {
				e.target.style.borderRight = "2px solid #0000ff";
			});

			div.addEventListener("mouseout", function(e) {
				e.target.style.borderRight = "";
			});

			document.addEventListener("mousemove", function(e) {
				if (curCol) {
					var diffX = e.pageX - pageX;

					if (nxtCol) nxtCol.style.width = nxtColWidth - diffX + "px";

					curCol.style.width = curColWidth + diffX + "px";
				}
			});

			document.addEventListener("mouseup", function() {
				curCol = undefined;
				nxtCol = undefined;
				pageX = undefined;
				nxtColWidth = undefined;
				curColWidth = undefined;
			});
		}

		function createDiv(height) {
			var div = document.createElement("div");
			div.style.top = 0;
			div.style.right = 0;
			div.style.width = "5px";
			div.style.position = "absolute";
			div.style.cursor = "col-resize";
			div.style.userSelect = "none";
			div.style.height = height + "px";
			return div;
		}

		function paddingDiff(col) {
			if (getStyleVal(col, "box-sizing") == "border-box") {
				return 0;
			}

			var padLeft = getStyleVal(col, "padding-left");
			var padRight = getStyleVal(col, "padding-right");
			return parseInt(padLeft) + parseInt(padRight);
		}

		function getStyleVal(elm, css) {
			return window.getComputedStyle(elm, null).getPropertyValue(css);
		}
	},
};
