export default {
    title: {
        value: "title", 
        display: true, 
        label: "Question Title",
        component: "v-text-field",
    },
    type: {
        value: "type", 
        items: ["tiles", "checkboxes", "comment", "html", "input", "matrix", "header"],
        display: true, 
        label: "Question Type",
        component: "v-select",
        default: "input"
    },
    answer_options: {
        value: "answer_options", 
        display: false,
        default: {}
    },
    mandatory: {
        value: "mandatory", 
        component: "v-checkbox",
        display: true,
        default: false,
        label: "Mandatory"
    },
    always_hidden: {
        value: "always_hidden", 
        component: "v-checkbox",
        display: false,
        default: false,
        label: "Always hide"
    },
    hide_from_results: {
        value: "hide_from_results", 
        component: "v-checkbox",
        display: true,
        default: false,
        label: "Hide from results tables"
    },
    in_library: {
        value: "in_library", 
        component: "v-checkbox",
        display: true,
        default: false,
        label: "Save in library"
    },
    custom_id: {
        value: "custom_id", 
        default: "",
        display: true,
        label: "Custom ID",
        component: "v-text-field"
    },
    help_text: {
        value: "help_text", 
        component: "v-text-field",
        label: "Help text",
        display: true
    },
    short_text: {
        value: "short_text", 
        component: "v-text-field",
        label: "Short text",
        display: true
    },
    visible_if: {
        value: "visible_if", 
        display: false,
        default: ""
    },
    content: {
        value: "content", 
        display: false
    },
    input_type: {
        value: "input_type", 
        component: "v-select",
        default: "text",
        options: {
            items: ["text", "number"]
        },
        display: false
    },
    disable_input: {
        value: "disable_input", 
        display: false
    },
    subquestions: {
        value: "subquestions", 
        display: false,
        default: null
    },
    multiple: {
        value: "multiple", 
        component: "v-checkbox",
        display: false,
        default: false,
        label: "Multiple answers"
    },
	profile_field: {
		value: "profile_field",
		default: "",
		display: true,
		multiple: false,
		label: "Profile Field",
		component: "MwSelect", 
		options: {
			storeModule: "profileFields",
			newPath: "NewProfileField",			
			itemText: "name",

		},
		require_id: false
	},
}

