export default {
	firestorePath: "users",
	firestoreRefType: "collection",
	moduleName: "users",
	statePropName: "data",
	namespaced: true,
	state: {
		icon: "mdi-account",
		name: {
			single: "User",
			plural: "Users",
		},
		path: "/users",
	},
	sync: {
		where: [
			[
				"status",
				"in",
				["published", "replaced", "updated", "new", "pending"],
			],
		],
		defaultValues: {
			status: "pending",
		},
	},
	getters: {
		getByEmail(state){
			return (email) => {
				let values = Object.values(state.data);
				return values.find( a => a.id == email )
			}
		},
		name(state) {
			return (id) => {
				let user = state.data[id];
				return user
					? user.name
						? user.name
						: user.first_name + " " + user.last_name
					: "";
			};
		},
	},
	mutations: {},
	actions: {
		fetchByEmail({ dispatch, state }, email) {
			let curr = state.data;
			email = email.trim();
			curr = Object.values(curr);
			let found = curr.find((a) => a.email == email);
			if (found) {
				return found.id;
			}
			return dispatch("fetchAndAdd", {
				where: [
					["email", "==", email],
					[
						"status",
						"in",
						["published", "replaced", "updated", "new", "pending"],
					],
				],
			}).then((res) => {
				if (!res.docs) {
					return false;
				}
				if (!res.docs.length) {
					return false;
				}
				return res.docs[0].id;
			});
		},
		fetchParticipants({ dispatch }) {
			dispatch("fetchAndAdd", {
				where: [
					["role", "==", "participant"],
					[
						"status",
						"in",
						["published", "replaced", "updated", "new", "pending"],
					],
				],
			});
		},
		new({ dispatch }, doc) {
			if (!doc.name) {
				doc.name = `${doc.first_name} ${doc.last_name}`;
			}
			if (!doc.registration_method) {
				doc.registration_method = "admin";
			}
			if (!doc.status) {
				doc.status = "published";
			}
			return dispatch("insert", doc).then((id) => {
				return id;
			});
		},
	},
};
