import utilities from "../../assets/utilities";
export default {
	firestorePath: "profileFieldValues",
	firestoreRefType: "collection",
	moduleName: "profileFieldValues",
	statePropName: "data",
	namespaced: true,
	state: {},
	sync: {
		defaultValues: {
			status: "published",
		},
	},
	serverChange: {
		addedHook: function(updateStore, doc, _, { dispatch }) {
			if( doc.question ){
				dispatch("questions/fetchById", doc.question );
			}
			return updateStore(doc);
		},
	},
	mutations: {},
	getters: {
		getByUserAndId(state){
			return ({id, user} ) => {
				if( !user ){
					return;
				}
				let values = Object.values(state.data);
				values = values.filter( v => v.profile_field == id );
				values.filter( a => a.user == user );
				values = utilities.sortByDate(values, "created_at" );
				return values;
			}
		}
	},
	actions: {
		fetchUserValues({ dispatch }, id) {
			return dispatch("fetchAndAdd", {
				clauses: {
					where: [
						["user", "==", id],
					],
				},
			});
		},
	},
};
