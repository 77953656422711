<template>
	<v-list-item class="menu-item" :router-link="link" :to="link"  :color="color"  @click="$emit('click')" >
		<v-list-item-icon >
			<v-icon :color="color">{{ icon }}</v-icon>
		</v-list-item-icon>

		<v-list-item-content >
			<v-list-item-title>{{ text }}</v-list-item-title>
		</v-list-item-content>
	</v-list-item>
</template>

<style lang="less">

	@import "../../assets/css/variables.less";
	.menu-item {
		cursor: pointer;
		&.v-list-item--active {
			.v-list-item__icon {
				color: @primary;
			}
			background-color: transparent;
		}
		.theme--light.v-list-item--active:hover::before, .theme--light.v-list-item--active::before {
			opacity: 0;
		}
	}	
</style>

<script>
export default {
	name: "NavItem",
	props: {
		icon: { type: String }, // mdi icon name
		text: { type: String }, // the text for the expanded item
		link: { type: String }, // the href if relevant
		color: { type: String, default: "primary" }, // the href if relevant
	},
};
</script>
