import Vue from "vue";
import VueRouter from "vue-router";
import firebase from "firebase";
Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		meta: {
			title: "Home",
			requires_auth: false
		},
		component: () =>
			import( "../views/Home.vue"),
	},
	{
		path: "/test",
		name: "Test",
		meta: {
			title: "Test",
			requires_auth: true
		},
		component: () =>
			import( "../views/Test.vue"),
	},
	{
		path: "/data-tools/for/:id",
		name: "for",
		props: true,
		meta: {
			title: "for",
			requires_auth: false
		},
		component: () =>
			import( "@c/data-tools/FindOldResponsesToProfileFields"),
	},
	{
		path: "/data-tools/dpf",
		name: "for",
		meta: {
			title: "for",
			requires_auth: false
		},
		component: () =>
			import( "@c/data-tools/DeleteProfileFields"),
	},
	{
		path: "/data-tools/prfp",
		name: "Prfp",
		meta: {
			title: "Prfp",
			requires_auth: false
		},
		component: () =>
			import( "@c/data-tools/ResponsesToProfileFields"),
	},
	{
		path: "/checklist",
		name: "Checklist",
		meta: {
			title: "Checklist",
			requires_auth: true
		},
		component: () =>
			import( "@c/checklist/AdminChecklist.vue"),
	},
	{
		path: "/payment-info/:id",
		name: "PaymentInfo",
		props: true,
		meta: {
			title: "PaymentInfo",
			requires_auth: true
		},
		component: () =>
			import( "@c/incentives/PaymentDetails.vue"),
	},
	{
		path: "/incentives/incentive-only",
		name: "IncentivesOnly",
		meta: {
			title: "IncentivesOnly",
			requires_auth: true
		},
		component: () =>
			import( "@c/incentives/IncentivesSubmissions.vue"),
	},
	{
		path: "/incentives/all",
		name: "AllIncentives",
		meta: {
			title: "Incentives",
			requires_auth: true
		},
		component: () =>
			import( "@c/incentives/AllIncentivesRecords.vue"),
	},
	{
		path: "/incentives/new",
		name: "NewIncentive",
		meta: {
			title: "New Incentive",
			requires_auth: true
		},
		component: () =>
			import( "@c/incentives/InsertIncentiveOnlySubmission.vue"),
	},
	{
		path: "/about",
		name: "About",
		meta: {
			title: "About",
			requires_auth: true
		},
		component: () =>
			import( "../views/About.vue"),
	},
	{
		path: "/error",
		name: "Error",
		meta: {
			title: "Error",
			requires_auth: true
		},
		component: () =>
			import( "../views/Error.vue"),
	},
	{
		path: "/communication-history/:id",
		name: "CommunicationHistory",
		meta: {
			title: "Communication",
			requires_auth: true
		},
		props: true,
		component: () =>
			import("@c/communication/CommunicationHistory.vue"),
	},
	{
		path: "/user-appointments/:id",
		name: "UserAppointments",
		props: true,
		meta: {
			title: "Appointments",
			requires_auth: true
		},
		component: () =>
			import( "@c/lists/UserAppointments.vue"),
	},
	{
		path: "/user-profile/:id",
		name: "UserProfile",
		props: true,
		meta: {
			title: "Profile",
			requires_auth: true
		},
		component: () =>
			import( "@c/profiles/ViewUserProfile.vue"),
	},
	{
		path: "/profile-search/:id",
		name: "ProfileSearch",
		props: true,
		meta: {
			title: "Search By Profile",
			requires_auth: true
		},
		component: () =>
			import( "@c/profiles/ProfileSearch.vue"),
	},
	{
		path: "/responses/:id",
		name: "SurveyResponses",
		meta: {
			title: "Responses",
			requires_auth: true
		},
		props: true,
		component: () =>
			import( "@c/lists/Responses.vue"),
	},
	{
		path: "/project-incentive-submissions/:id",
		name: "IncentiveOnlyProjectIncentives",
		meta: {
			title: "Incentive Submissions",
			requires_auth: true
		},
		props: true,
		component: () =>
			import( "@c/incentives/IncentivesSubmissionsByProject.vue"),
			
	},
	{
		path: "/project-appointments/:id",
		name: "ProjectAppointments",
		meta: {
			title: "Appointments",
			requires_auth: true
		},
		props: true,
		component: () =>
			import( "@c/edits/EditProjectAppointments.vue"),
	},
	{
		path: "/documents/:id",
		name: "ProjectDocuments",
		meta: {
			title: "Documents",
			requires_auth: true
		},
		props: true,
		component: () =>
			import( "@c/lists/Documents.vue"),
	},
	{
		path: "/project-email-fields/:id",
		name: "ProjectEmailFields",
		meta: {
			title: "Fields",
			requires_auth: true
		},
		props: true,
		component: () =>
			import( "@c/edits/EditProjectEmailFields.vue"),
	},
	{
		path: "/project-candidates/:id",
		name: "ProjectCandidates",
		props: true,
		meta: {
			title: "Candidates",
			requires_auth: true
		},
		component: () =>
			import( "@c/responses/ProjectCandidates.vue"),
	},
	{
		path: "/video/:id",
		name: "Video",
		meta: {
			title: "Video",
			requires_auth: true
		},
		props: true,
		component: () =>
			import("../views/Video.vue"),
	},
	{
		path: "/currently-recruiting/:id",
		name: "ProjectRecruitingTile",
		meta: {
			title: "Currently Recruiting",
			requires_auth: true
		},
		props: true,
		component: () =>
			import( "@c/edits/EditProjectRecruitingTile.vue"),
	},
	{
		path: "/currently-recruiting",
		name: "CurrentlyRecruiting",
		meta: {
			title: "Currently Recruiting",
			requires_auth: true
		},
		props: true,
		component: () =>
			import( "@c/lists/CurrentlyRecruiting.vue"),
	},
	{
		path: "/excluded-participants/:id",
		meta: {
			title: "Trash",
			requires_auth: true
		},
		name: "ExcludedParticipants",
		props: true,
		component: () =>
			import( "@c/edits/EditExcludedParticipants.vue"),
	},
	{
		path: "/project-blocker/:id",
		meta: {
			title: "Slots",
			requires_auth: true
		},
		name: "ProjectSlots",
		props: true,
		component: () =>
			import( "@c/blocker/EditProjectSlots.vue"),
	},

	{
		path: "/scheduling/:id",
		name: "ProjectSchedule",
		meta: {
			title: "Schedule",
			requires_auth: true
		},
		props: true,
		component: () =>
			import( "@c/scheduler/EditProjectSchedule.vue"),
	},
];

import modules from "./list_new_edit_modules";
import store from "../store";
modules.forEach((m) => {
	routes.push(m);
})


const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

function setTitle(string){
	store.commit("SET_TITLE", string )
}
router.beforeEach(async (to, from, next) => {
	const requiresAuth = to.matched.some((record) => record.meta.requires_auth);
	if( to.meta.title ){
		setTitle( to.meta.title );
	}
	if (requiresAuth && !(await firebase.getCurrentUser())) {
		next("/");
	} else {
		next();
	}
});

export default router;
