export default {
	state: {
	},
	getters: {
		all(_, __, rootState) {
            let appointmentParticipants = Object.values(rootState.appointmentParticipants.data);
            let rows = appointmentParticipants.map( p => {
                let row = {...p};
                let appointment = rootState.appointments.data[row.appointment];
                if( !appointment ){
                    return;
                }
                if( row.status == "deleted" || appointment.status == "deleted" ){
                    return;
                }
                ['project', 'start', 'quota', 'methodology', 'participation_link'].forEach( key => {
                    row[`appointment_${key}`] = appointment[key];
                })
                return row;
            } )
            return rows.filter( r => r );
		},
        getByFilters(_, getters) {
			return ({
				projects = [],
				date_start,
				date_end,
				users = [],
				payment_statuses = [],
				appointment_statuses = [],

			}) => {
                let all = getters.all;
				if (projects.length) {
					all = all.filter((f) =>
						projects.includes(f.appointment_project)
					);
				}
				if (date_start) {
					all = all.filter((f) => {
						return f.appointment_start > date_start;
					});
				}
				if (date_end) {
					all = all.filter((f) => {
						return f.appointment_start < date_end;
					});
				}
                if (users.length) {
					all = all.filter((f) =>
						users.includes(f.user)
					);
				}
                if (users.length) {
					all = all.filter((f) =>
						users.includes(f.user)
					);
				}
                if (payment_statuses.length) {
					all = all.filter((f) =>
						payment_statuses.includes(f.payment_status)
					);
				}
                if (appointment_statuses.length) {
					all = all.filter((f) =>
						appointment_statuses.includes(f.appointment_status)
					);
				}
                return all;

			};
		},
	},
    actions: {
        fetchAppointmentsForParticipants({dispatch}, ids){
            dispatch("appointments/fetchByIds", ids, {root: true } )
        },
        fetchParticipantsForAppointments({dispatch}, ids){
            ids.forEach( id => {
                dispatch("appointmentParticipants/fetchByKey", {key: "appointment", value: id}, {root: true } )
            })
        },
        fetchDocuments({dispatch}, {collection, clauses}){
            dispatch(`${collection}/fetchAndAdd`, { clauses }, {root: true} ).then( res => {
                if( !res.empty ){
                    if( collection == "appointments" ){
                        let ids = res.docs.map( d => d.id );
                        dispatch("fetchParticipantsForAppointments", ids );
                    }
                    if( collection == "appointmentParticipants" ){
                        let ids = res.docs.map( d => d.data().appointment );
                        dispatch("fetchAppointmentsForParticipants", ids );
                    }
                }
            })
        },
        fetchByFilters(
			{ dispatch },
			{
				user,
				appointment_status,
				date_start,
				date_end,
				projects,
                payment_statuses = [],
			}
		) {
            let where = [];
            let collection = "";

			if (user || appointment_status || payment_statuses.length) {
                collection = "appointmentParticipants";
                if (payment_statuses.length) {
                    where.push(["payment_status", "in", payment_statuses]);
                }
                if (user) {
                    where.push(["user", "==", user]);
                }
                if (appointment_status) {
                    where.push(["appointment_status", "==", appointment_status]);
                }
                
			} 
            else {
                collection = "appointments";
                if (projects.length) {
                    where.push(["project", "in", projects]);
                }
                if (date_start) {
                    where.push(["start", ">", date_start]);
                }
                if (date_end) {
                    where.push(["start", "<", date_end]);
                }
			}

            dispatch("fetchDocuments", {clauses: {where}, collection});
		},

    },
	namespaced: true,
};
            // fetchByFilters(
            //     { dispatch },
            //     {
            //         projects = [],
            //         date_start,
            //         date_end,
            //         // users = [],
            //         // payment_statuses = [],
            //         // appointment_statuses = [],
            //     }
            // ) {
            //     let where = [];
            //     return dispatch("fetchAndAdd", { clauses: { where, limit: -1 } });
            // },
