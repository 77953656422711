import mwutils from "../../assets/utilities";
import AppointmentParticipant from "../../models/AppointmentParticipant.js";

let empty = mwutils.getEmpty(AppointmentParticipant);

export default {
	firestorePath: "appointmentParticipants",
	firestoreRefType: "collection",
	moduleName: "appointmentParticipants",
	statePropName: "data",
	namespaced: true,
	state: {},
	sync: {
		defaultValues: {
			...empty,
			status: "published",
		},
	},
	serverChange: {
		addedHook: function(updateStore, doc, _, { dispatch }) {
			if (doc.user) {
				dispatch("users/fetchById", doc.user);
			}
			return updateStore(doc);
		},
	},
	getters: {
		byAppointment(state) {
			return (id) => {
				let participants = Object.values(state.data);
				return participants.filter((a) => a.appointment == id);
			};
		},
		byProject(_, getters, __, rootGetters) {
			return (id) => {
				let appointments = rootGetters[
					"appointments/activeAppointmentsForProject"
				](id);
				return appointments.flatMap((appointment) => {
					return getters.byAppointment(appointment.id);
				});
			};
		},
		getPaymentRecords(state) {
			return (type, status) => {
				let appointments = Object.values(state.data);
				appointments = appointments.filter(
					(a) => a.appointment_status == type
				);
				return appointments.filter((a) => a.payment_status == status);
			};
		},
	},
	mutations: {},
	actions: {
		cancel({ dispatch }, id) {
			dispatch("patch", { id, appointment_status: "cancelled" });
		},
	},
};
