<template>
	<v-app>
		<top-nav></top-nav>
		<sidebar></sidebar>
		<v-main>
			<v-container class="pa-8">
				<router-view />
			</v-container>
		</v-main>
		<toast></toast>
	</v-app>
</template>
<style lang="less">
@import "./assets/css/variables";
.v-application {
	font-family: @mw-body-font, sans-serif !important;
  font-weight: 300;
	// font-size: 14px;
}
</style>
<script>		

import Vuex from "vuex";
import TopNav from "@c/navigation/Top.vue";
import Toast from  "@c/notices/Toast.vue"
import Sidebar from "@c/navigation/Side.vue";
export default {
	name: "App",
	data: () => ({
		saved: false,
		title: "",
	}),
	computed: {
		...Vuex.mapState({
			state: (state) => state,
		}),
		pageTitle(){
			return this.$store.state.page_title 
		},
		siteTitle(){
			return this.$store.state.site_title 
		},
		isMobile(){
			return this.$vuetify.breakpoint.smAndDown
		}
	},
	provide() {
		return {
			isMobile: this.isMobile
		}
	},
	components: {
		TopNav,
		Sidebar,
		Toast
	},
	watch: {
		pageTitle: {
			immediate: true,
			handler(value){
				document.title = `${value} - ${this.siteTitle}`; 
			}
		}
	},
	created(){
		this.$store.dispatch("config/openDBChannel");
	}

};
</script>
