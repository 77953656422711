import utilities from "../../assets/utilities";

export default {
	firestorePath: "rewards",
	firestoreRefType: "collection",
	moduleName: "incentives",
	statePropName: "data",
	namespaced: true,
	state: {
		surveyId: "PambZAkLatUBP8dnOirY",
		icon: "mdi-currency-gbp",
		name: {
			single: "Incentive",
			plural: "Incentives",
		},
		path: "/incentives/all",
	},
	getters: {
		survey(state, _, rootState) {
			let id = state.surveyId;
			return rootState.surveys.data[id];
		},
		getKeyedDetails(state, getters, _, rootGetters) {
			return (id) => {
				let survey = getters.survey;
				if (!survey) {
					return [];
				}
				let responses = Object.values(state.data).filter(
					(a) => a.user == id
				);
				responses = utilities.sortByDate(responses, "created_at");
				let response = responses[0];
				if (!response) {
					return null;
				}

				let row = {
					reward_id: response.id,
					user: response.user,
					created_at: response.created_at.toDate().toLocaleDateString(),
				};
				survey.questions.forEach((q) => {
					let short = rootGetters["questions/shortText"](q);
					row[short] = response.data[q];
				});
				return row;
			};
		},
	},
	mutations: {},
	actions: {
		start({ dispatch, state }) {
			dispatch("surveys/fetchById", state.surveyId, { root: true });
		},
		fetchUserRecords({ dispatch }, id) {
			dispatch("fetchByKey", { key: "user", value: id });
		},
	},
};
