import Vue from "vue";
import Vuex from "vuex";
import firebase from "firebase";

import { createStore } from 'vuex-extensions'
import VuexEasyFirestore from "vuex-easy-firestore";

Vue.use(Vuex);

import state from "./state";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";


import singleAppointments from "./modules/singleAppointments.js";
import tableHeaders from "./modules/tableHeaders.js";

import modules from "./moduleLoader.js"


const vxf = VuexEasyFirestore(
	Object.values(modules),
	{
		logging: true,
		FirebaseDependency: firebase,
	}
);

const mwstoreutils =  {
	actions: {
		fetchByKey: ({dispatch}, {key, value } ) => {
			dispatch(`openDBChannel`, {clauses: {limit: -1, where: [ [ key, "==", value ], ["status", "==", "published"]]}})
		},
		fetchByIds: ({dispatch}, ids) => {
			if(!ids ){
				return;
			}
			ids.forEach( id => {
				dispatch("fetchById", id ); 
			})
		},
		fetchByIdOr: ({ dispatch }, {roles, contacts } ) => {
			dispatch("fetchAndAdd", {
				clauses: { where: [[roles, "array-contains-any", contacts ], ["status", "==", "published"]] },
			})
		},
		new({dispatch}, doc ){
			return dispatch("insert", doc ).then( id => {
				return id
			})
		}

	}
}
const storeData = {
	actions,
	getters, 
	mutations,
	state,
	modules: {tableHeaders, singleAppointments},
	plugins: [vxf],
	mixins: mwstoreutils,
};

const store =  createStore(Vuex.Store, storeData );


export default store;
