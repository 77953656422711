import appointments from "./modules/appointments.js";
import appointmentParticipants from "./modules/appointmentParticipants.js";
import auth from "./modules/auth.js";
import categories from "./modules/categories.js";
import categoryOptions from "./modules/categoryOptions.js";
import config from "./modules/config.js";
import consents from "./modules/consents.js";
import content from "./modules/content.js";
import emails from "./modules/emails.js";
import incentives from "./modules/incentives.js";
import incentiveSubmissions from "./modules/incentiveSubmissions.js";
import messages from "./modules/messages.js";
import organisations from "./modules/organisations.js";
import participantAvailability from "./modules/participantAvailability.js";
import profileFields from "./modules/profileFields.js";
import profileFieldValues from "./modules/profileFieldValues.js";
import projects from "./modules/projects.js";
import questions from "./modules/questions.js";
import surveys from "./modules/surveys.js";
import templates from "./modules/templates.js";
import videoRoom from "./modules/videoRoom.js";
import videoRooms from "./modules/videoRooms.js";
import surveyResponses from "./modules/surveyResponses.js";
import users from "./modules/users.js";

export default {
    appointments,
    appointmentParticipants,
    auth,
    categoryOptions,
    categories,
    config,
    content,
    consents,
    emails,
    incentives,
    incentiveSubmissions,
    messages,
    organisations,
    participantAvailability,
    profileFields,
    profileFieldValues,
    projects,
    questions,
    surveys,
    templates,
    surveyResponses,
    videoRooms,
    videoRoom,
    users
};
