export default {
	firestorePath: "surveyResponses",
	firestoreRefType: "collection",
	moduleName: "surveyResponses",
	statePropName: "data",
	namespaced: true,
	state: {},
	sync: {
		// where: [["status", "in", ["published"]]],
		// defaultValues: {
		// 	status: "published",
		// },
	},
	serverChange: {
		addedHook: function(updateStore, doc, _, { dispatch }) {
			var participant = doc.participant;
			if( participant && typeof participant == "string" ){
				dispatch("users/fetchById", participant );
			}
			return updateStore(doc);
		},
	},
	getters: {

    },
	mutations: {},
	actions: {
        fetchBySurvey({dispatch}, id ){
            dispatch( "openDBChannel", {clauses: {where: [[ "survey", "==", id], ["status", "in", ["submitted", "processed"]]]}})
        }
    },
	model: {},
};
