import Question from "../../models/Question.js";
import mwutils from "../../assets/utilities";
import { arrayUnion, arrayRemove } from "vuex-easy-firestore";

let empty = mwutils.getEmpty(Question);

export default {
	firestorePath: "surveys",
	firestoreRefType: "collection",
	moduleName: "surveys",
	statePropName: "data",
	namespaced: true,
	state: {
		icon: "mdi-file-question",
		name: {
			single: "Survey",
			plural: "Surveys",
		},
		path: "/surveys",
	},
	sync: {
		where: [["status", "in", ["published"]]],
		defaultValues: {
			...empty, status: "published" 
		},
	},	
	serverChange: {
		addedHook: function(updateStore, doc, _, { dispatch }) {
			var questions = doc.questions;
			dispatch("questions/fetchByIds", questions);
			return updateStore(doc);
		},
	},
	getters: {
		getPreviousQuestions(state){
			return function( id, question ){
				const survey = state.data[id];
				if( !survey ){
					return;
				}
				let ids = survey.questions.join(",").split(question);
				let previous = ids[0];
				return previous.split(",").filter( q => q);
			}
		}
	},
	mutations: {},
	actions: {
		addQuestion({dispatch}, {id, question}){
			dispatch("patch", {id, questions: arrayUnion(question)})
		},
		removeQuestion({dispatch}, {id, question}){
			dispatch("patch", {id, questions: arrayRemove(question)})
		}
	},
	model: {},
};
