import store from "@/store";
let modules = [
	"users",
	"questions",
	"profileFields",
	"categories",
	"content",
	"templates",
	"surveys",
	"projects",
	"organisations",
];

let paths = modules.flatMap((m) => {
	let s = store.state[m];
	let single = s.name.single.split(" ").join("");
	let plural = s.name.plural.split(" ").join("");


	return [
		{
			path: `${s.path}`,
			name: plural,
			meta: {
				requires_auth: true,
				title: plural,
			},
			component: () => import(`@/components/lists/${plural}.vue`),
		},

		{
			path: `${s.path}/new`,
			name: `New${single}`,
			props: true,
			meta: {
				title: plural,
				requires_auth: true,
				sidebar: true,
			},
			component: () => import(`@/components/edits/Edit${single}.vue`),
		},

		{
			path: `${s.path}/:id`,
			name: `Edit${single}`,
			props: true,
			meta: {
				requires_auth: true,
				// title: plural,
				sidebar: true,
			},
			component: () => import(`@/components/edits/Edit${single}.vue`),
		},
	];
});

export default paths;
